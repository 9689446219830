import FAQ from "@/comps/feature/faq"
import HeadlineCover from "@/comps/feature/headline"
import ReviewsLanding from "@/comps/feature/reviews"
import { Text } from "@/comps/feature/text"
import { UsecasesAlternative } from "@/comps/feature/usecases-alternative"
import Footer from "@/comps/footer"
import Header from "@/comps/header"
import { NewAchievements } from "@/comps/home/marketplace/achievements"
import Packages from "@/comps/home/packages"
import { Runner } from "@/comps/home/platforms/runner"
import Meta from "@/comps/meta"
import useBranchRedirect from "@/utils/branch"
import { assetUrl } from "@/utils/cdn"
import {
	SSRTemporaryRedirect,
	wrapSSRAuth,
} from "@/utils/ssr-auth"
import useUtmIdentifiers from "@/utils/utm"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import {
	Organization,
	SoftwareApplication,
	WithContext,
} from "schema-dts"
import { createGlobalStyle } from "styled-components"
import { parse } from "url"

const GlobalStyle = createGlobalStyle`
	html {
		background: var(--color-background);
	}
`

const organizationJsonLd: WithContext<Organization> = {
	"@context": "https://schema.org",
	"@type": "Organization",
	url: "https://zoomerang.app/",
	logo: "https://filesw.zoomerang.info/Web/comps/logo-light.svg",
	name: "Zoomerang",
	email: "info@zoomerang.app",
	description:
		"Social media video and photo creating and editing platform for businesses and creators.",
	address:
		"2035 Sunset Lake Road, Suite B-2, Newark, 19702, United States, DE",
	brand: {
		"@type": "Brand",
	},
	sameAs: [
		"https://www.youtube.com/@Zoomerang",
		"https://www.linkedin.com/company/zoomerang-app/",
		"https://www.facebook.com/zoomerangapp/",
		"https://www.tiktok.com/@zoomerang_app",
		"https://www.instagram.com/zoomerang.app",
	],
	contactPoint: {
		"@type": "ContactPoint",
		contactType: "https://zoomerang.app/contact",
	},
	founder: {
		"@type": "Person",
		name: "David Grigoryan",
		image:
			"https://media.licdn.com/dms/image/C4D03AQHVkDMGmcp2-A/profile-displayphoto-shrink_800_800/0/1614498297830?e=1712188800&v=beta&t=ORL87aHLo8KJ0ujGcycQOM34ZVAaU1RWgIgdHZCvLTQ",
		url: "https://www.linkedin.com/in/grigoryandavid/",
		jobTitle: "Co-Founder and CEO",
	},
	legalName: "© 2024 Zoomerang Inc.",
	slogan: "Create Anything from Nothing With AI",
}

const softwareApplicationJsonLd: WithContext<SoftwareApplication> =
	{
		"@context": "https://schema.org",
		"@type": "SoftwareApplication",
		name: "Zoomerang AI Video Maker",
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "4.9",
			ratingCount: 355459,
			bestRating: "5",
			worstRating: "0",
		},
		applicationCategory: "MultimediaApplication",
		offers: {
			"@type": "Offer",
			price: "0",
			priceCurrency: "USD",
		},
		review: {
			"@type": "Review",
			author: {
				"@type": "Person",
				name: "Wayne Davis",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				worstRating: "0",
				bestRating: "5",
			},
		},
		operatingSystem:
			"Windows, Mac OS, Linux, Chrome OS, iOS, Android",
		url: "https://zoomerang.app/",
		applicationSubCategory: "Video Maker",
		featureList: "https://zoomerang.app/tools",
	}

const Home = () => {
	const { t } = useTranslation()
	useBranchRedirect({ users: true, templates: true })
	useUtmIdentifiers()

	return (
		<>
			<Meta
				title={t("txt_home_title")}
				description={t("txt_home_description")}
				jsonLdData={[
					organizationJsonLd,
					softwareApplicationJsonLd,
				]}
			/>
			<div
				className={clsx(
					"flex w-full flex-col items-center bg-color-cell",
				)}>
				<GlobalStyle />
				<Header />
				<HeadlineCover
					component="headline"
					bubble_text={t("txt_top_bubble_text")}
					title={t("txt_top_title")}
					subtitle={t("txt_top_subtitle")}
					media={assetUrl("/home/new/top_video_v2.webm")}
					right_icon={assetUrl("/home/new/right-arrow.svg")}
					button_text={t("txt_start_now")}
					button_link="/home"
					style="large"
					tag="h1"
					video_thumb={assetUrl(
						"/home/new/top_image_v4.webp",
					)}
					without_qr
					logEvent="header"
				/>
				<Runner />
				<UsecasesAlternative
					component="usecases_alternative"
					style="large"
					videos={[
						assetUrl("/home/new/inline_video_2.mp4"),
						assetUrl("/home/new/inline_video_1.mp4"),
						assetUrl("/home/new/inline_video_3.mp4"),
						assetUrl("/home/new/inline_video_4.mp4"),
					]}
				/>
				<NewAchievements />
				<Text
					component="text"
					title={t("txt_ai_backed")}
					tag="h2"
					padding_bottom={30}
					padding_top={10}
				/>
				<HeadlineCover
					component="headline"
					bubble_text={t("txt_url_to_video_bubble")}
					title={t("txt_url_to_video_title")}
					subtitle={t("txt_url_to_video_subtitle")}
					media={assetUrl("/home/new/url_to_video.webm")}
					button_text={t("txt_try_now")}
					button_link="/home"
					style="large"
					tag="h2"
					image_left
					video_thumb={assetUrl(
						"/home/new/url_to_video_v2.webp",
					)}
					without_qr
					logEvent="url_to_video"
				/>
				<HeadlineCover
					component="headline"
					bubble_text={t("txt_script_to_video_bubble")}
					title={t("txt_script_to_video_title")}
					subtitle={t("txt_script_to_video_subtitle")}
					media={assetUrl("/home/new/script_to_video.webm")}
					button_text={t("txt_try_now")}
					button_link="/home"
					style="large"
					tag="h2"
					video_thumb={assetUrl(
						"/home/new/script_to_video_v3.webp",
					)}
					without_qr
					logEvent="script_to_video"
				/>
				<HeadlineCover
					component="headline"
					bubble_text={t("txt_ai_avatars_bubble")}
					title={t("txt_ai_avatars_title")}
					subtitle={t("txt_ai_avatars_subtitle")}
					media={assetUrl("/home/new/ai_avatars.webm")}
					button_text={t("txt_try_now")}
					button_link="/home"
					style="large"
					tag="h2"
					video_thumb={assetUrl(
						"/home/new/ai_avatars_v2.webp",
					)}
					image_left
					without_qr
					logEvent="ai_avatars"
				/>

				<Text
					component="text"
					title={t("txt_simple_chat")}
					tag="h2"
					padding_bottom={50}
					padding_top={0}
				/>

				<HeadlineCover
					component="headline"
					bubble_text="step 1"
					title={t("txt_step_1_title")}
					subtitle={t("txt_step_1_subtitle")}
					media={assetUrl("/home/new/step-1-v2.webm")}
					style="large"
					tag="h2"
					className="py-[35px] tablet:py-[40px] desktop:py-0"
					image_left
					without_qr
					video_thumb={assetUrl("/home/new/step-1_v3.webp")}
				/>
				<HeadlineCover
					component="headline"
					bubble_text="step 2"
					title={t("txt_step_2_title")}
					subtitle={t("txt_step_2_subtitle")}
					media={assetUrl("/home/new/step-2-v2.webm")}
					style="large"
					tag="h2"
					className="py-[35px] tablet:py-[40px] desktop:py-0"
					image_left
					without_qr
					video_thumb={assetUrl("/home/new/step-2_v2.webp")}
				/>

				<HeadlineCover
					component="headline"
					bubble_text="step 3"
					title={t("txt_step_3_title")}
					subtitle={t("txt_step_3_subtitle")}
					media={assetUrl("/home/new/step-3-v2.webm")}
					style="large"
					tag="h2"
					className="py-[35px] tablet:py-[40px] desktop:py-0"
					image_left
					without_qr
					video_thumb={assetUrl("/home/new/step-3_v2.webp")}
				/>

				<HeadlineCover
					component="headline"
					bubble_text="step 4"
					title={t("txt_step_4_title")}
					subtitle={t("txt_step_4_subtitle")}
					media={assetUrl("/home/new/step-4-v2.webm")}
					style="large"
					tag="h2"
					className="py-[35px] tablet:py-[40px] desktop:py-0"
					image_left
					without_qr
					video_thumb={assetUrl("/home/new/step-4_v3.webp")}
				/>

				<HeadlineCover
					component="headline"
					bubble_text="step 5"
					title={t("txt_step_5_title")}
					subtitle={t("txt_step_5_subtitle")}
					media={assetUrl("/home/new/step-5-v2.webm")}
					style="large"
					tag="h2"
					className="py-[35px] tablet:py-[40px] desktop:py-0"
					image_left
					without_qr
					video_thumb={assetUrl("/home/new/step-5_v3.webp")}
				/>

				<ReviewsLanding
					component="reviews"
					title={t("txt_review_title")}
					without_background
					reviews={reviews.map((a) => {
						return {
							name: t(a.name),
							rating: a.rating,
							opinion: t(a.opinion),
							avatar: a.avatar,
						}
					})}
				/>

				<Packages />

				<FAQ
					component="faq"
					style="large"
					questions={questions.map((a) => {
						return {
							question: t(a.question),
							answer: t(a.answer),
						}
					})}
				/>
				{/* <Crisp percent={10} page="home" /> */}
				<Footer />
			</div>
		</>
	)
}

const questions = [
	{
		question: "txt_question_1",
		answer: "txt_question_1_answer",
	},
	{
		question: "txt_question_2",
		answer: "txt_question_2_answer",
	},
	{
		question: "txt_question_3",
		answer: "txt_question_3_answer",
	},
	{
		question: "txt_question_4",
		answer: "txt_question_4_answer",
	},
	{
		question: "txt_question_5",
		answer: "txt_question_5_answer",
	},
	{
		question: "txt_question_6",
		answer: "txt_question_6_answer",
	},
]

const reviews = [
	{
		name: "txt_alex_d_name",
		rating: 5,
		opinion: "txt_alex_d_opinion",
		avatar: assetUrl("/home/new/avatars/alex-d-v2.webp"),
	},
	{
		name: "txt_donald_r_name",
		rating: 5,
		opinion: "txt_donald_r_opinion",
		avatar: assetUrl("/home/new/avatars/donald-r-v2.webp"),
	},
	{
		name: "txt_louis_g_name",
		rating: 5,
		opinion: "txt_louis_g_opinion",
		avatar: assetUrl("/home/new/avatars/louis-g-v2.webp"),
	},
	{
		name: "txt_theo_p_name",
		rating: 5,
		opinion: "txt_theo_p_opinion",
		avatar: assetUrl("/home/new/avatars/theo-p-v2.webp"),
	},
	{
		name: "txt_marcus_t_name",
		rating: 5,
		opinion: "txt_marcus_t_opinion",
		avatar: assetUrl("/home/new/avatars/marcus-t.webp"),
	},
	{
		name: "txt_priya_k_name",
		rating: 5,
		opinion: "txt_priya_k_opinion",
		avatar: assetUrl("/home/new/avatars/priya-k-v2.webp"),
	},
	{
		name: "txt_brian_l_name",
		rating: 5,
		opinion: "txt_brian_l_opinion",
		avatar: assetUrl("/home/new/avatars/brian-l.webp"),
	},
	{
		name: "txt_jason_p_name",
		rating: 5,
		opinion: "txt_jason_p_opinion",
		avatar: assetUrl("/home/new/avatars/jason-p.webp"),
	},
	{
		name: "txt_chloe_z_name",
		rating: 5,
		opinion: "txt_chloe_z_opinion",
		avatar: assetUrl("/home/new/avatars/chloe-z-v2.webp"),
	},
]

export const getServerSideProps = wrapSSRAuth(
	async (context, userInfo) => {
		const { req } = context

		let redirectUrl = "/home"
		if (req.url !== undefined) {
			const parsedUrl = parse(req.url, true)
			const query = parsedUrl.query

			const queryParams = new URLSearchParams()
			Object.keys(query).forEach((key) => {
				const value = query[key]
				if (Array.isArray(value)) {
					value.forEach((val) =>
						queryParams.append(key, val),
					)
				} else if (value !== undefined) {
					queryParams.append(key, value)
				}
			})

			const queryString = queryParams.toString()
			redirectUrl = `/home?${queryString}`
		}

		if (!userInfo.isAnonymous) {
			throw new SSRTemporaryRedirect(redirectUrl)
		}
	},
	[],
)

export default Home
